import React from 'react';
import './TopicsPage.css';

export default function CollageTextbox({ children }) {
  return (
    <div className="collage-text">
      <div
        style={{
          color: 'red',
          width: '23px',
          height: '30px',
          //   backgroundColor: 'red',
          float: 'right',
        }}
      ></div>
      <p>{children}</p>
    </div>
  );
}
