import React, { useEffect, useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import './Liked.css';
// import { favoriteVideo } from '../../store/auth';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';
import { videoActions } from '../../store/video';
import { motion } from 'framer-motion';

export default function Liked({ videoId, favorited }) {
  const [liked, setLiked] = useState(favorited);

  const dispatch = useDispatch();

  useEffect(() => {
    if (liked != favorited) {
      setLiked((prev) => !prev);
    }
  }, [favorited]);

  const likeVideo = async () => {
    console.log({ videoId });
    console.log(videoId.videoId);
    // dispatch(authActions.likeVideo({ videoId, favorited: !favorited }));
    // const justViewed = await fetch(
    //   `${
    //     process.env.REACT_APP_BACKEND
    //   }/api/v1/users/favorite/${videoId}/${!liked}`,
    //   {
    //     credentials: 'include', // include, *same-origin, omit
    //   }
    // );
    // const viewResponse = await justViewed.json();

    // console.log(viewResponse);
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: '5px',
        top: '5px',
        zIndex: 180,
        pointerEvents: 'all',
      }}
      onClick={(e) => {
        e.stopPropagation();
        setLiked((prev) => !prev);
        // likeVideo();
        dispatch(favoriteVideo(videoId, favorited));
      }}
    >
      {liked ? (
        <div className="liked-background">
          <motion.div
            style={{ lineHeight: '20px' }}
            initial={{ scale: 0.5 }}
            animate={{ scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 300, // Controls the "bounciness" of the spring
              damping: 10, // Controls the "friction" of the spring
            }}
            // exit={width < 800 && { translateX: '100%' }}
            // transition={{ ease: 'easeOut', duration: 0.3 }}
          >
            <FavoriteIcon
              // fontSize="medium"
              style={{
                // color: '#ca2577',
                color: 'white',
                fontSize: '25px',
                // color: '#BEA7EF',
                // filter: 'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.41))',
              }}
            />
          </motion.div>
        </div>
      ) : (
        <div className="liked-background">
          <FavoriteBorderIcon
            // fontSize="medium"
            style={{ color: '#FFFFFF', fontSize: '25px' }}
          />
        </div>
      )}
    </div>
  );
}

export function favoriteVideo(videoId, liked) {
  console.log('inthinkkk!');
  return async (dispatch, getState) => {
    // dispatch(authActions.likeVideo({ videoId, liked }));
    const oldLike = liked;
    // dispatch(authActions.likeVideo({ videoId, favorited: !liked }));
    // return;
    // const { video, auth } = getState();
    // const selectedSections = video.selectedSections;
    // const section = {
    //   user: auth.user._id,
    //   video: video.currentVideo.id,
    //   sections: selectedSections,
    //   looptime: video.looptime,
    //   label,
    //   frequency,
    //   sectionLabels: selectedSections.map(
    //     (index) => video.currentVideo.sections[index].label
    //   ),
    //   notes,
    //   test: test || false,
    // };

    // console.log('👕 section to upload: ', section);

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_BACKEND
        }/api/v1/users/favorite/${videoId}/${!liked}`,
        {
          credentials: 'include', // include, *same-origin, omit
        }
      );
      const filledResponse = await response.json();
      // console.log(response);
      if (!response.ok) {
        // dispatch(authActions.likeVideo({ videoId, favorited: oldLike }));
        console.log('in failed if');
        alert('fail');
      } else {
        console.log({ filledResponse });
        dispatch(authActions.updateAllFavorites(filledResponse.data.data));
        dispatch(
          videoActions.addFavoriteToCarousel({ videoId, liked: !liked })
        );

        // dispatch(
        //   videoActions.addSavedSelections({
        //     newSection: filledResponse.data,
        //     practiceDictionary: filledResponse.practiceDictionary,
        //   })
        // );

        // addSectionHandler(section);
      }
    } catch (err) {
      // dispatch(authActions.likeVideo({ videoId, favorited: oldLike }));
    }
  };
}
