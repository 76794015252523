import React, { useState } from 'react';
import './DayProgress.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const weekAbbrv = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
// const checked = [1, 3, 4, 5];

export default function DayProgress() {
  const [checked, setChecked] = useState([1, 3, 4, 5]);

  return (
    <div className="progress-outer">
      <div className="week-contents">
        {weekAbbrv.map((day, index) => (
          <div className="day-n-circle-container">
            <div
              className="cancel-dragDEP"
              onClick={() => {
                if (checked.includes(index)) {
                  setChecked((prev) => prev.filter((el) => el !== index));
                } else {
                  setChecked((prev) => [...prev, index]);
                }
              }}
            >
              {checked.includes(index) ? (
                <CheckCircleIcon />
              ) : (
                <RadioButtonUncheckedIcon />
              )}
            </div>
            <div className="single-day-digit">{day}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
