import { AnimatePresence } from 'framer-motion';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import VideoBoxAndThumb from './components/VideoBoxAndThumb';
import VisualSelection from './components/VisualSelection';
import Divider from '@mui/material/Divider';
import isEqual from 'lodash/isEqual';

let count = 0;

export default function VideoPage({
  user,
  myUploads,
  sharedWithMeVideos,
  allVideos,
  recentVideos,

  favoriteVideos,
  onTreeVideoClickHandler,
  isMobile,
  addSearchObject,
}) {
  console.log('🎉 VideoPage rerender: ', count++);
  const showAllVideoRef = useRef(null);
  const [showAllVideoModal, setShowAllVideoModal] = useState();
  const [currentSectionId, setCurrentSectionId] = useState(undefined);
  const [newVideoArray] = useSelector(
    (state) => [
      state.auth.user?.newVideos,
      // state.auth.user?.favoriteVideos,
    ],
    isEqual
  );

  console.log({ newVideoArray });

  return (
    <>
      <div
        id="showMeAll"
        ref={showAllVideoRef}

        // style={{ width: '100%', height: '100%' }}
      >
        {/* <AnimatePresence>{showAllVideoModal}</AnimatePresence> */}
      </div>
      <AnimatePresence>
        {(currentSectionId === 3 || currentSectionId === undefined) &&
          favoriteVideos?.length > 0 && (
            <VisualSelection
              videoSectionId={3}
              key="favVids"
              title="Favorites"
              isMobile={isMobile}
              dateSorted={false}
              setCurrentSectionId={setCurrentSectionId}
              showAllVideoRef={showAllVideoRef}
            >
              {favoriteVideos.map((videoData) => (
                <VideoBoxAndThumb
                  passKeyThrough={'favVids_' + videoData.id}
                  title={videoData.folderLocation}
                  videoData={videoData}
                  onTreeVideoClickHandler={onTreeVideoClickHandler}
                  addSearchObject={addSearchObject}
                  isNew={newVideoArray?.includes(videoData.id)}
                />
              ))}
            </VisualSelection>
          )}
      </AnimatePresence>

      {(currentSectionId === 0 || currentSectionId === undefined) &&
        user?.recentlyViewedVideos?.length > 0 && (
          <VisualSelection
            videoSectionId={0}
            key="Recent"
            title="Recently Viewed"
            showCount={false}
            isMobile={isMobile}
            dateSorted={false}
            onTreeVideoClickHandler={onTreeVideoClickHandler}
            setCurrentSectionId={setCurrentSectionId}
            showAllVideoRef={showAllVideoRef}
            setShowAllVideoModal={setShowAllVideoModal}
          >
            {recentVideos.map((videoData, index) => (
              <VideoBoxAndThumb
                passKeyThrough={'recent_' + videoData.id}
                title={videoData.folderLocation}
                videoData={videoData}
                onTreeVideoClickHandler={onTreeVideoClickHandler}
                addSearchObject={addSearchObject}
                videoTest={index === 0}
              />
            ))}
          </VisualSelection>
        )}
      {(currentSectionId === 1 || currentSectionId === undefined) &&
      [...myUploads, ...sharedWithMeVideos].length > 0 ? (
        <>
          {/* <Divider primary="Full width variant below" /> */}
          <VisualSelection
            videoSectionId={1}
            key="All"
            title="All Videos"
            isMobile={isMobile}
            withFilters={true}
            setCurrentSectionId={setCurrentSectionId}
            showAllVideoRef={showAllVideoRef}
            borderBottom={false}
          >
            {[
              // ...myUploads.map((el) => {
              //   return { ...el, butt: 0 };
              // }),
              // ...sharedWithMeVideos.map((el) => {
              //   return { ...el, butt: 1 };
              // }),
              { label: 'My Uploads', videos: myUploads },
              { label: 'Shared with Me', videos: sharedWithMeVideos },
            ].map((bundle) => {
              const obj = {
                ...bundle,
                videos: bundle.videos.map((videoData) => (
                  <VideoBoxAndThumb
                    passKeyThrough={'allVids_' + videoData.id}
                    title={videoData.folderLocation}
                    videoData={videoData}
                    onTreeVideoClickHandler={onTreeVideoClickHandler}
                    addSearchObject={addSearchObject}
                    isNew={newVideoArray?.includes(videoData.id)}
                  />
                )),
              };
              return obj;
            })}
          </VisualSelection>
        </>
      ) : (
        <>
          {currentSectionId === undefined && (
            <p style={{ textAlign: 'center', marginTop: '20px' }}>
              {'No videos yet :('}
            </p>
          )}
        </>
      )}
      {/* {sharedWithMeVideos[0] && (
        <VisualSelection key="share" title="Shared with Me" isMobile={isMobile}>
          {sharedWithMeVideos.map((videoData) => (
            <VideoBoxAndThumb
              // key={'sharedWithMe_' + videoData.id}
              passKeyThrough={'sharedWithMe_' + videoData.id}
              title={videoData.folderLocation}
              videoData={videoData}
              onTreeVideoClickHandler={onTreeVideoClickHandler}
              addSearchObject={addSearchObject}
            />
          ))}
        </VisualSelection>
      )} */}
      {(currentSectionId === 2 || currentSectionId === undefined) &&
        allVideos?.length > 0 && (
          <VisualSelection
            videoSectionId={2}
            key="AllForAdmin"
            title="All Videos"
            isMobile={isMobile}
            setCurrentSectionId={setCurrentSectionId}
            showAllVideoRef={showAllVideoRef}
          >
            {allVideos.map((videoData) => (
              <VideoBoxAndThumb
                passKeyThrough={'allVids_' + videoData.id}
                title={videoData.folderLocation}
                videoData={videoData}
                onTreeVideoClickHandler={onTreeVideoClickHandler}
                addSearchObject={addSearchObject}
                isNew={newVideoArray?.includes(videoData.id)}
              />
            ))}
          </VisualSelection>
        )}
    </>
  );
}
