import './TopicsPage.css';
import React, { useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { Responsive, WidthProvider } from 'react-grid-layout';

// import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';

import Divider from '@mui/material/Divider';
// import 'react-grid-layout/css/styles.css';
import './react-grid-layout.css';

import Topic from './Topic';

// const ResponsiveGridLayout = WidthProvider(Responsive);

export default function TopicsPage({ onTreeVideoClickHandler }) {
  const [savedSelections, practiceDictionary] = useSelector(
    (state) => [state.video.savedSelections, state.video.practiceDictionary],
    isEqual
  );
  // console.log({ savedSelections });
  console.log('render topic page');

  const [expandedTab, setExpandedTab] = useState(-1);
  const topicPageRef = useRef(null);
  const currentTabIndex = useRef(null);

  useEffect(() => {
    // setTimeout(() => {
    //   topicPageRef.current.scrollTo({
    //     // top: Math.max(0, sectionHeight * currentBar - 10),
    //     top: 65,
    //     behavior: 'smooth',
    //   });
    //   console.log('fucckkcda');
    // }, 500);
    if (expandedTab !== -1) {
      topicPageRef.current.scrollTo({
        // top: Math.max(0, sectionHeight * currentBar - 10),
        top: expandedTab === -1 ? 0 : 65 * expandedTab,
        behavior: 'smooth',
      });
    }
  }, [expandedTab]);

  const toggleTab = (index) => {
    console.log('look at ref: ', topicPageRef.current);

    setExpandedTab(expandedTab === index ? -1 : index);
    // currentTabIndex.current =
    if (expandedTab === index) {
      topicPageRef.current.scrollTo({
        // top: Math.max(0, sectionHeight * currentBar - 10),
        top: 0,
        behavior: 'smooth',
      });
    }
    console.log('scroll top: ', topicPageRef.current.scrollTop);
  };

  //   const [layouts, setLayouts] = useState(
  //     JSON.parse(JSON.stringify(originalLayouts))
  //   );

  //   const onLayoutChange = (layout, newLayouts) => {
  //     console.log('insideLayoutChange');
  //     // console.log({ layout });
  //     // console.log({ layouts });
  //     // if (JSON.stringify(layouts) !== JSON.stringify(newLayouts)) {
  //     saveToLS('layouts', newLayouts);
  //     setLayouts(newLayouts);
  //     // }
  //   };

  return (
    <div
      className="topic-page-container"
      ref={topicPageRef}
      style={{
        overflowY: expandedTab > -1 ? 'hidden' : 'scroll',
        overflowX: 'visible',
      }}
    >
      <div
        style={{
          // height: '1400px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          alignItems: 'center',
        }}
      >
        {practiceDictionary.map(
          (topic, index) => (
            // true || expandedTab === -1 || index === expandedTab ? (
            <>
              <Topic
                expanded={expandedTab === index}
                topicPageRef={topicPageRef}
                afterOpen={expandedTab !== -1 && index > expandedTab}
                topic={topic}
                index={index}
                toggleTab={toggleTab}
                //   onLayoutChange={onLayoutChange}
                //   layouts={layouts}
                //   ResponsiveGridLayout={ResponsiveGridLayout}
                onTreeVideoClickHandler={onTreeVideoClickHandler}
              />
              {/* <Divider
                variant="middle"
                // component="li"
                sx={{ borderBottomWidth: 5, marginBottom: 1, color: 'red' }}
                // style={{ borderColor: 'black' }}
              /> */}
            </>
          )
          // ) : undefined
        )}
        <div
          style={{
            height: '5px',
            flexGrow: 1,
            backgroundColor: 'var(--background-light-gray)',
            position: 'relative',
            zIndex: 100,
          }}
        ></div>
      </div>
    </div>
  );
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      'rgl-8',
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
