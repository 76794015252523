import React, { useState, useEffect } from 'react';
import classes from './TabPanel.module.css';
import { motion } from 'framer-motion';
import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { videoActions } from '../../store/video';
import { useSelector } from 'react-redux';
import TimerIcon from '@mui/icons-material/Timer';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import SearchIcon from '@mui/icons-material/Search';
import LoopIcon from '@mui/icons-material/Loop';
import OpenInFullOutlinedIcon from '@mui/icons-material/OpenInFullOutlined';
import IconTextButton from '../Buttons/IconTextButton';

let count = 0;

export default function TabPanel({ tabIndex, remaining, tabs }) {
  console.log('🐝 tab panel rerender: ', count++, { tabIndex });
  const dispatch = useDispatch();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 3,
      padding: '0 4px',
      background: `${'red'}`,
      color: 'var(--background-light-gray)',
    },
  }));

  return (
    <div className={classes['outer-container']}>
      {tabs.map(
        (tab, index) =>
          tab && (
            <div
              className={classes['tab']}
              highlight={String(tabIndex === index)}
              onClick={() => {
                dispatch(videoActions.updateTabIndex(index));
              }}
            >
              {tab.badgeCount ? (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  // transition={{ type: 'spring', stiffness: 300 }}
                  className={classes['tab-badge-container']}
                >
                  <StyledBadge badgeContent={tab.badgeCount} />
                </motion.div>
              ) : (
                <></>
              )}
              <div>{tab.name}</div>

              {tabIndex === index && (
                <motion.div
                  layoutId="tabSelector"
                  className={classes['underline-tab']}
                />
              )}
            </div>
          )
      )}
    </div>
  );
}
