import React from 'react';
import { useDispatch } from 'react-redux';
import classes from './VideoBoxAndThumb.module.css';
import { videoActions } from '../store/video';
import { motion } from 'framer-motion';

export default function VideoInfoWrapper({ passKeyThrough, children }) {
  const dispatch = useDispatch();

  //   console.log('👨🏻‍💻  videoData', videoData);
  return (
    <div
      className={classes['main-container']}
      // style={{ overflow: 'visible' }}
      key={passKeyThrough}
      //   onClick={() => {
      //     onTreeVideoClickHandler(id, selection);
      //     dispatch(videoActions.updateRecentVideos(videoData));
      //     // dispatch(videoActions.addVideos(data.data));
      //   }}
      initial={{ scale: 1, opacity: 1 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.2, opacity: 0 }}
      transition={{
        scale: { duration: 0.5, ease: 'easeInOut', delay: 0.5 },
        opacity: { duration: 0.5, ease: 'easeInOut', delay: 0.5 },
      }}
    >
      {children}
    </div>
  );
}
