import React, { useEffect, useRef, useState } from 'react';
import ModalInline from '../ModalInline';
import FrequencySlider from './FrequencySlider';
import './PracticeForm.css';
import { submitSection, videoActions } from '../../store/video';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { tourActions } from '../../store/tour';
import useInput from '../../hooks/use-input';

export default function PracticeForm({
  // selectedSections,
  // sections,
  defaultTitle,
  onModalClose,
}) {
  const dispatch = useDispatch();

  const practiceDictionary = useSelector((state) => {
    return state.video.practiceDictionary;
  });

  const practiceCategories = practiceDictionary
    .map((el) => el.name)
    .filter((el) => el !== 'unSorted');

  console.log({ practiceCategories });

  // const [title, setTitle] = useState('');

  const {
    enteredValue: title,
    hasError: titleHasError,
    onBlurHandler: titleBlurHandler,
    onChangeHandler: titleChangeHandler,
    isValidValue: enteredTitleIsValid,
  } = useInput((value) => value.trim().length > 0);

  // const [title, setTitle] = useState(defaultTitle);
  const [notes, setNotes] = useState('');
  const [category, setCategory] = useState('unSorted');
  const [customCategory, setCustomCategory] = useState(null);
  const [repeat, setRepeat] = useState(2);
  const [forTesting, setForTesting] = useState(false);
  const [testCount, setTestCount] = useState(1);

  const customCategoryRef = useRef(null);

  useEffect(() => {
    if (category === 'create') {
      customCategoryRef.current?.focus();
    }
  }, [category]);

  console.log({ title });
  console.log({ notes });
  console.log({ category });
  console.log({ repeat });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!enteredTitleIsValid) {
      titleBlurHandler(event);
      return;
    }

    // console.log('result for event 4: ', event.target[4]?.value || 1);
    // alert(event.target[2].value);
    // we have the for loop to create duplicate cards which were used when debugging the animations (we needed a lot to 'complete' and 'delete' and 'snooze')
    // const count = Math.min(15, event.target[4]?.value || 1);
    // for (let i = 0; i < count; i++) {
    // console.log('calling dispatch: ', i);
    let categoryRefined;
    if (category === 'create') {
      if (customCategory) {
        categoryRefined = customCategory;
      } else {
        categoryRefined = 'unSorted';
      }
    } else {
      categoryRefined = category;
    }

    console.log({ category });
    console.log({ categoryRefined });
    if (category === 'unSorted') {
      categoryRefined = undefined;
    }
    // alert(categoryRefined);
    // return;
    // let nextPracticeDictionary;
    // if (category != 'unSorted') {
    //   if (practiceCategories.includes(category)) {
    //   } else {
    //     nextPracticeDictionary = [
    //       ...practiceDictionary,
    //       {
    //         name: category,
    //         items: [action.payload.newSection._id],
    //       },
    //     ];
    //   }
    // }

    dispatch(
      submitSection(title, notes, repeat, categoryRefined)
      // submitSection(title, notes, repeat, event.target[3]?.value || false)
    );
    dispatch(tourActions.handleLinkedAction('submitPractice'));
    // }
    onModalClose();
  };

  return (
    <ModalInline fixedHeight={false} onModalClose={onModalClose}>
      <form onSubmit={handleSubmit} className="practice-form-container">
        <h2 style={{ color: 'black', fontSize: '20px', fontWeight: 'bold' }}>
          Create Practice Section
        </h2>
        <div>
          <label>Title*</label>
          <input
            type="text"
            // defaultValue={defaultTitle}
            value={title}
            onBlur={titleBlurHandler}
            onChange={titleChangeHandler}
            style={{ width: '100%' }}
          ></input>
          {titleHasError && (
            <div style={{ color: 'red' }}>A title is required!</div>
          )}
        </div>
        <div>
          {' '}
          <label>
            <span>Notes </span>
            {/* <span style={{ color: 'var(--text-light-gray)' }}>(optional)</span> */}
          </label>
          <textarea
            style={{ width: '100%' }}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            onInput={(e) => {
              e.target.style.height = 'auto';
              e.target.style.height = e.target.scrollHeight + 3 + 'px';
            }}
          />
        </div>
        {/* <label>Frequency</label> */}
        {/* <FrequencySlider /> */}
        {/* <div> */}{' '}
        <div className="practice-frequency-question">
          <div>
            <span>Category </span>
            {/* <span style={{ color: 'var(--text-light-gray)' }}>(optional)</span> */}
          </div>
          <div style={{ margin: '0 5px' }}>
            {category != 'create' && (
              <select
                name="category"
                id="category"
                onChange={(e) => {
                  setCategory(e.target.value);
                  if (e.target.value === 'create') {
                    setCustomCategory('');
                  }
                }}
              >
                <option value="create">create new +</option>
                {practiceCategories.map((category) => (
                  <option value={category}>{category}</option>
                ))}
                <option value="unSorted" selected="selected">
                  none
                </option>
              </select>
            )}
            {category == 'create' && (
              <input
                ref={customCategoryRef}
                type="text"
                value={customCategory}
                id="custom-category-input"
                // placeholder="new+"
                onChange={(e) => setCustomCategory(e.target.value)}
              ></input>
            )}
          </div>
        </div>
        <div className="practice-frequency-question">
          <div>Repeat every</div>
          <div style={{ margin: '0 5px' }}>
            <select
              name="repeat-count"
              id="repeat-count"
              onChange={(e) => {
                setRepeat(Number(e.target.value));
              }}
            >
              <option value={1}>1</option>
              <option value={2} selected="selected">
                2
              </option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
            </select>
          </div>
          <div>days</div>
        </div>
        {/* </div> */}
        {/* {process.env.REACT_APP_ENV === 'dev' && (
          <div>
            <h2 style={{ marginTop: '10px' }}>Dev tools</h2>
            <div className="practice-frequency-question">
              <div>For testing </div>
              <div style={{ margin: '0 5px' }}>
                <select name="testing-bool" id="testing-bool">
                  <option value={true} selected="selected">
                    True
                  </option>
                  <option value={false}>False</option>2
                </select>
              </div>
            </div>
            <div>
              <span>How many? </span>
              <input
                type="text"
                defaultValue={1}
                style={{ width: '50px' }}
              ></input>
            </div>
          </div>
        )} */}
        {/* <div className="label-container-practice" style={{ marginTop: '20px' }}>
          {selectedSections.map((index, i) => (
            <>
              <div
                className="label-in-practice-cards"
                // style={{ color: 'blue', borderColor: 'blue' }}
              >
                {sections[index].label?.length === 0
                  ? '??'
                  : sections[index].label}
              </div>
              {selectedSections.length - 1 != i && (
                <div
                  className="arrow-between-labels"
                  // style={{ color: 'blue' }}
                >
                  →
                </div>
              )}
            </>
          ))}
        </div> */}
        <div
          style={{
            minHeight: '70px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            gap: '10px',
          }}
        >
          <button
            id="cancel-submit-new-section"
            className="practice-button"
            onClick={onModalClose}
          >
            Cancel
          </button>
          <button
            id="submit-new-section"
            className="practice-button"
            type="submit"
          >
            Create
          </button>
        </div>
      </form>
    </ModalInline>
  );
}
