import React, { useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import Hls from 'hls.js';
import './TopicVid.css';
import TimerIcon from '@mui/icons-material/Timer';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';

export default function TopicVid({
  url,
  looptime,
  completed,
  clickHandler,
  autoPlay,
}) {
  //   const looptime = useRef([0, Math.random() * 10]); // Loop between 0 and 5 seconds

  const playerRef = useRef(null); // Ref to control the ReactPlayer
  const handleProgress = (state) => {
    if (
      state.playedSeconds >= looptime[1] ||
      state.playedSeconds <= looptime[0]
    ) {
      playerRef.current.seekTo(looptime[0], 'seconds'); // Reset to the start of the loop
    }
  };

  useEffect(() => {
    if (playerRef?.current.getCurrentTime() < looptime[0]) {
      playerRef.current.seekTo(looptime[0], 'seconds'); // Reset to the start of the loop
    }
  }, []);
  // console.log('show me selectedSections?.length: ', selectedSections?.length);
  const HLSConfig = {
    forceKeyFrameOnDiscontinuity: false,
    maxBufferLength: 1, // for example, set to 30 seconds
    maxMaxBufferLength: 2.5, // hard upper limit for maxBufferLength. This does not seem to update in the reactPlayer even when HLSConfig changes
    // Set a maximum buffer size to control the amount of data preloaded.
    maxBufferSize: 6 * 1024 * 1024, // for example, set to 10MB
  };

  return (
    <div className={`topic-vid-container`} onClick={clickHandler}>
      <ReactPlayer
        ref={playerRef}
        // className="player-preview"
        className={`player-preview ${completed ? 'grayscale-image' : ''}`}
        // url={`${videoData.url}/filename.m3u8`}
        url={`${url}/hls1m.m3u8`}
        // url="https://d2qxuoym2zs537.cloudfront.net/hhh3/filename.m3u8"
        loop={false}
        onProgress={handleProgress} // Monitor progress
        config={{
          // hlsOptions: hlsConfig,
          // file: { forceVideo: true },
          file: {
            forceHLS: true,
            hlsOptions: HLSConfig,
            hlsVersion: Hls.version,
            hlsjs: Hls,
          },
        }}
        width="100%"
        height="100%"
        // playing={false}
        playing={!completed && autoPlay}
        // volume={volume}
        muted={true}
        // onProgress={progressHandler}
        // onBuffer={bufferStartHandler}
        // onBufferEnd={bufferEndHandler}
        // progressInterval={progressInterval}
        // playbackRate={playbackRate}
        // onReady={onReady}
        // onStart={onStart}
        // onPlay={onPlay}
      />
      {completed && (
        <div className="completed-topic-vid">
          {/* <TimerIcon /> */}
          <HourglassTopIcon style={{ fontSize: '20px' }} />
          <div>2d</div>
        </div>
      )}
    </div>
  );
}
