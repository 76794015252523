import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { submitNewNote, useUpdateNote, videoActions } from '../../store/video';
import './NewAndEditNote.css';
import CloseIcon from '@mui/icons-material/Close';

export default function NewAndEditNote({
  initialText,
  setTemporarilyHideControls,
  setAddingNote,
}) {
  const dispatch = useDispatch();
  const [text, setText] = useState(initialText);
  const textRef = useRef(null);

  const [userVideoDataId, index] = useSelector((state) => [
    state.video.userVideoData._id,
    state.video.selectedSections[0],
  ]);

  const {
    loading: isSending,
    error: errorSending,
    sendData: sendDataFromHook,
  } = useUpdateNote(userVideoDataId, index, text, [text], () => {
    setAddingNote(false);
  });

  useEffect(() => {
    if (textRef.current) {
      textRef.current.focus();
      textRef.current.setSelectionRange(
        textRef.current.value.length,
        textRef.current.value.length
      );
    }
    return () => {
      setTemporarilyHideControls(false);
    };
  }, []);

  const handleChange = (event) => {
    console.log('text in NewANdEditNote: ', JSON.stringify(text));
    setText(event.target.value);
  };

  const saveButton = (
    <button
      className={`save-note-button note-button ${
        errorSending ? 'save-note-button-fail' : ''
      }`}
      onClick={sendDataFromHook}
    >
      {isSending ? '...' : errorSending ? 'retry' : 'save'}
    </button>
  );

  return (
    <motion.div
      className="textarea-and-button-container"
      initial={{ y: -100, opacity: 1 }} // Start off-screen at the top with 0 opacity
      animate={{ y: 0, opacity: 1 }} // Animate to its final position with full opacity
      transition={{
        type: 'spring', // Smooth bounce effect
        stiffness: 50, // Adjust spring stiffness
        damping: 10, // Adjust damping for smoother motion
        duration: 0.5, // Fallback duration
      }}
    >
      <div className="textarea-container" style={{ whiteSpace: 'pre-wrap' }}>
        {`${text?.length > 0 ? text : 'd'}${text?.endsWith('\n') ? ' ' : ''}`}

        <div>
          <textarea
            ref={textRef}
            className="note-from-main-controls"
            type="text"
            value={text}
            onChange={handleChange}
            style={{ background: 'none', zIndex: 6 }}
            //   onBlur={handleBlur}
            //   style={textStyle}
          />
        </div>
        <div className="save-note-button-container">
          {/* <div className="exit-note-x ">X</div> */}
          {/* <button className="note-button exit-note-x">
            <CloseIcon />
          </button> */}
          {saveButton}
        </div>
      </div>
      {/* {saveButton} */}
    </motion.div>
  );
}
