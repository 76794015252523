import React, { useEffect, useState, useRef } from 'react';
import Header from './components/Header';
import Auth from './components/Auth';
import './App.css';
import './GlobalCSSVariables.css';
import classes from './components/Header.module.css';
import { useSelector } from 'react-redux';
import NewVideoForm from './components/NewVideoForm';
import { useDispatch } from 'react-redux';
import { videoActions } from './store/video';
import { authActions } from './store/auth';
import VideoContainer from './components/video_components/VideoContainer';
import TabPanel from './components/Header/TabPanel';
import ResetPassword from './components/ResetPassword';
import isEqual from 'lodash/isEqual';
import { tourActions } from './store/tour';
import { motion } from 'framer-motion';
import VideoContainerModal from './components/VideoContainerModal';

// import { useLocation } from 'react-router-dom';
let count = 0;

function ViewSlider({ children }) {
  // const location = useLocation();

  // useEffect(() => {
  //   if (location?.pathname === '/demo') {
  //     alert('demo!');
  //   }
  // }, []);
  // alert(location.pathname);

  const [tabIndex, isAuth, isMobile, user, videoData, newVideoCount] =
    useSelector(
      (state) => [
        state.video.tabIndex,
        state.auth.isAuthenticated,
        state.settings.isMobile,
        state.auth.user,
        state.video.currentVideo,
        state.auth.user?.newVideos?.length,
      ],
      isEqual
    );

  // const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);

  const [addingVideo, setAddingVideo] = useState(false);
  const [cookieChecked, setCookieChecked] = useState(false);
  const [showResetPasswordPage, setShowResetPasswordPage] = useState(false);

  // const [tabIndex, setTabIndex] = useState(1);
  const [showVideoPage, setShowVideoPage] = useState(false);
  const filePickerRef = useRef(null);

  const dispatch = useDispatch();

  const onHomeClick = () => {
    // setSearchArray([]);
    dispatch(videoActions.resetCurrentVideo());
    dispatch(tourActions.handleLinkedAction('homeButton'));
  };

  // const addSearchObject = (obj) => {
  //   // setAutoCompleteValue(obj);
  //   setTabIndex(3);
  // };

  // useEffect(() => {
  //   if (tabIndex === 2 && !showVideoPage) {
  //     setShowVideoPage(true);
  //   }
  //   if (tabIndex === 3) {
  //     // setIsOpen(true);
  //     // document.querySelector('.MuiInputBase-input').focus();
  //   }
  // }, [tabIndex]);

  if (!isAuth) {
    console.log('not logged in but gonna check cookie');
    const checkForLoggedIn = async () => {
      console.log('right before fect');
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND}/api/v1/users/checkIfLoggedIn`,
          {
            method: 'POST',
            credentials: 'include', // include, *same-origin, omit
          }
        );

        console.log('rsponse from cookie check: ', response);
        if (!response.ok) {
          console.log('not currently logged in via cookie');
        } else {
          console.log('logged in via cookie');
          const data = await response.json();
          console.log(data.data.user);
          dispatch(authActions.login(data.data.user));
          dispatch(
            videoActions.initializePracticeDictionary(
              data.data.user.practiceDictionary
            )
          );
          // alert('is mobile: ', String(isMobile));
          // console.log('is mobile: ', isMobile);

          if (isMobile) {
            dispatch(
              tourActions.checkForUnseenTours(data.data.user.toursViewed)
            );
          }
        }
      } catch (err) {
        console.log(err);
      }

      setCookieChecked(true);
    };
    if (!cookieChecked) checkForLoggedIn();
  }

  const onNewVidClickHandler = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    console.log('👅 on new video click handler, before set addingvideo treu');

    if (file) {
      console.log('file exissts');
      setSelectedFile(file);
      alert('size is: ' + (file.size / 1024 / 1024).toFixed(1) + 'MB');
      reader.readAsDataURL(file);
      setAddingVideo(true);
    }

    reader.onload = (readerEvent) => {
      setVideoPreview(readerEvent.target.result);
    };
  };

  const onAddVideo = () => {
    // filePickerRef.current.click();
  };

  const closeNewVideoModal = () => {
    console.log('exit new vid modal');
    setVideoPreview(null);
    filePickerRef.current.value = null;
    setAddingVideo(false);
  };

  return (
    <>
      {!isAuth && cookieChecked ? (
        <>
          {showResetPasswordPage ? (
            <ResetPassword />
          ) : (
            <Auth setShowResetPasswordPage={setShowResetPasswordPage} />
          )}
        </>
      ) : (
        <div className="outermost-container">
          {addingVideo && (
            <NewVideoForm
              onModalClose={closeNewVideoModal}
              src={videoPreview}
              selectedFile={selectedFile}
            />
          )}
          <motion.div
            layout
            id="timer-container"
            className="timer-on-top"
            // style={{
            //   width: '100%',
            //   transitionProperty: 'height',
            //   position: 'relative',
            // }}
          ></motion.div>
          <Header
            usersName={'fudge'}
            onAddVideo={onAddVideo}
            tabIndex={tabIndex}
          >
            <TabPanel
              tabIndex={tabIndex}
              // setTabIndex={setTabIndex}
              // newVideoCount={newVideoCount}
              remaining={2}
              tabs={[
                { name: 'Practice' },
                { name: 'Videos', badgeCount: newVideoCount },
                { name: 'Search' },
                user?.role == 'admin' ? { name: 'Topics' } : undefined,
              ]}
            />
          </Header>
          <motion.div
            // layout
            className="three-panel-container"
            style={{
              // position: 'relative',
              // display: 'flex',
              // flexDirection: 'row',
              // flexGrow: 1,
              width: '400%',
              left: `-${tabIndex}00%`,
              // transition: 'left .15s',
              // visibility: `${videoData ? 'hidden' : 'visible'}`,
            }}
          >
            {children}
          </motion.div>
          <input
            ref={filePickerRef}
            accept="video/*"
            onChange={onNewVidClickHandler}
            type="file"
            hidden
          />

          {videoData && (
            <VideoContainerModal onModalClose={onHomeClick}>
              <VideoContainer
                // setSearchArray={setSearchArray}
                videoData={videoData}
                userId={user._id}
                onHomeClick={onHomeClick}
              />
            </VideoContainerModal>
          )}
        </div>
      )}
    </>
  );
}

export default ViewSlider;
