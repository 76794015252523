import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './Topic.css';
import WidgetWrapper from './WidgetWrapper';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import DayProgress from './DayProgress';
import './DayProgress.css';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AddIcon from '@mui/icons-material/Add';
import MonthProgress from './MonthProgress';
import TopicVid from './TopicVid';
import GridLayout from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PlayDisabledIcon from '@mui/icons-material/PlayDisabled';
import CollageTextbox from './CollageTextbox';
import { AnimatePresence, motion } from 'framer-motion';
import Divider from '@mui/material/Divider';
import isEqual from 'lodash/isEqual';
import UserProfile from './components/UserProfile';

const ResponsiveGridLayout = WidthProvider(Responsive);
const cols = { sm: 6, xs: 4, xxs: 3 };
const notes = {
  size: { w: 1, h: 2 },
  items: [
    `Duis id mauris sagittis, gravida dui a, imperdiet urna. Sed
eu dolor id lacus efficitur congue sed sed erat.`,
    `Lorem ipsum dolor sit amet, consectetur adipiscing elit.
Phasellus consequat, nulla rutrum sagittis cursus, elit nisl
condimentum est, id suscipit sem metus pretium turpis.`,
  ],
};

export default function Topic({
  expanded,
  afterOpen,
  topic,
  index,
  toggleTab,
  onTreeVideoClickHandler,
  topicPageRef,
}) {
  //   console.log('😱 render Topcivid: ', topic);
  const originalLayouts = getFromLS(topic._id) || {};

  // given an array of items from topic.items, create a default layout for a given size (xxs, s, m) where each item is filled into the layout based on a default size
  const items = topic.items;
  const collageContainerRef = useRef(null);

  console.log('🍋‍🟩 Topic render index: ', index);
  const [autoPlay, setAutoPlay] = useState(false);
  const [locked, setLocked] = useState(true);
  const [showHeader, setShowHeader] = useState(true);
  const prevScrollTop = useRef(0);
  const scrollTrigger = useRef(0);
  const [savedSelections] = useSelector(
    (state) => [state.video.savedSelections],
    isEqual
  );

  useEffect(() => {
    const items = topic.items;
    console.log({ items });
  }, []);

  useEffect(() => {
    // console.log('collageContainerRef: ', collageContainerRef.current);
    // console.log('made it to scroll creator at index: ', index);
    const handleScroll = () => {
      const scrollValue = collageContainerRef.current.scrollTop;

      let triggerHide = false;
      const triggerDiff = 60;
      const scrollDif = scrollValue - prevScrollTop.current;
      if (scrollDif > 0) {
        scrollTrigger.current = scrollValue;
      } else if (Math.abs(scrollValue - scrollTrigger.current) > triggerDiff) {
        // console.log(
        //   'Math.abs(scrollValue - scrollTrigger.current) : ',
        //   Math.abs(scrollValue - scrollTrigger.current)
        // );
        triggerHide = true;
      }
      prevScrollTop.current = scrollValue;

      if (triggerHide || scrollValue < 65) {
        if (!showHeader) {
          setShowHeader(true);
          //     }
        }
      } else if (scrollValue > 65) {
        if (showHeader) {
          setShowHeader(false);
        }
      }
      //   if (scrollValue > 65) {
      //     if (showHeader) {
      //       setShowHeader(false);
      //     }
      //   } else if (!showHeader) {
      //     setShowHeader(true);
      //   }
    };

    const element = collageContainerRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }

    // Cleanup event listener on component unmount
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [showHeader]);

  const [layouts, setLayouts] = useState(() => {
    if (Object.keys(originalLayouts).length !== 0) {
      console.log('layout: but cached: ', originalLayouts);
      return JSON.parse(JSON.stringify(originalLayouts));
    } else {
      const layoutProto = {};
      Object.keys(cols).forEach((letterSizing) => {
        let columnCount = cols[letterSizing];
        let rowIndex = 0,
          columnIndex = 0;
        // const sizes = { w: 1, h: 2 };
        const calcNextEl = (item, index, defaultSize, id) => {
          const nextEl = {
            x: columnIndex,
            y: rowIndex,
            w: defaultSize.w,
            h: defaultSize.h,
            i: id ? id + index : item,
          };
          if (columnIndex + defaultSize.w < columnCount) {
            columnIndex = columnIndex + defaultSize.w;
          } else {
            columnIndex = 0;
            rowIndex = rowIndex + 2;
          }
          // columnIndex = (columnIndex + 1) % 3;

          return nextEl;
        };
        layoutProto[letterSizing] = [
          calcNextEl('dayProgress', 0, { w: 2, h: 1 }),
          ...items.map((item, index) =>
            calcNextEl(item, index, { w: 1, h: 2 })
          ),
          ...notes?.items.map((item, index) =>
            calcNextEl(item, index, { w: 1, h: 2 }, 'note')
          ),
        ];
      });
      //   const layoutProto = [];
      console.log({ layoutProto });
      return layoutProto;
    }
  });
  //   console.log({ layouts });

  const onLayoutChange = (layout, newLayouts) => {
    console.log('insideLayoutChange');
    // console.log({ layout });
    // console.log({ newLayouts });

    saveToLS(topic._id, newLayouts);
    setLayouts(newLayouts);
    // }
  };

  return (
    <div
      // key="animatedDiv"
      //   ref={collageContainerRef}
      className={`topic-container ${afterOpen ? 'after-open' : ''}`}
      key={index}
      style={{ zIndex: index + 30, position: 'relative' }}
      //   onClick={() => toggleTab(index)}
    >
      <AnimatePresence>
        {showHeader && (
          <motion.div
            key={`topic-header-${index}`}
            initial={{
              y: -50,
            }}
            animate={{
              y: 0,
            }}
            exit={{ y: -50 }}
            transition={{
              duration: 0.2, // Animation duration
            }}
            className={`topic-heading ${expanded ? 'open' : ''} `}
            onClick={() => toggleTab(index)}
          >
            <ExpandMoreOutlinedIcon
              className={`topic-caret ${expanded ? 'caret-rotated' : ''}`}
            />
            <div className="topic-name">{topic.name}</div>
            {/* <div className="topic-emoticon">{topic.feels}</div> */}

            {expanded ? (
              <>
                <AddIcon />
                {/* <div>kslk</div> */}
                <div
                  //   style={{ height: 'fit-content' }}
                  style={{
                    height: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setAutoPlay((prev) => !prev);
                  }}
                >
                  {autoPlay ? <PlayDisabledIcon /> : <PlayArrowIcon />}
                </div>
                <div
                  style={{
                    maxHeight: '24px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setLocked((prev) => !prev);
                  }}
                >
                  {!locked ? <LockOpenOutlinedIcon /> : <LockOutlinedIcon />}
                </div>
              </>
            ) : (
              <>
                <div className="days-in-header">
                  {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
                    <span
                      className={
                        Math.random() > 0.7
                          ? 'day-marked-bold'
                          : 'day-NOT-marked-bold'
                      }
                    >
                      {day}
                    </span>
                  ))}
                </div>
                <div style={{ width: '20px', aspectRatio: 1 }}>
                  {['', '😌', '🫤', '🔥', '😳', 'F', 'S'][index]}
                </div>
              </>
            )}
            {/* <div style={{ width: '20px', aspectRatio: 1 }}>
          {['', '😌', '🫤', '🔥', '😳', 'F', 'S'][index]}
        </div> */}

            {/* <ExpandMoreOutlinedIcon
          className={`topic-caret ${expanded ? 'caret-rotated' : ''}`}
        /> */}
          </motion.div>
        )}
      </AnimatePresence>
      {/* Tab Content */}
      <div
        ref={collageContainerRef}
        className={`topic-video-container 
            ${!locked ? 'unlocked' : ''}`}
      >
        <div style={{ width: '100%', height: '65px' }} />
        <AnimatePresence>
          {expanded && (
            //   && topic.videos

            <motion.div
              key="animatedDiv"
              initial={{
                //   height: '100%',
                //   height: topicPageRef.current.offsetHeight,
                height: topicPageRef.current.offsetHeight - 65,
                opacity: 1,
              }}
              animate={{
                //   height: '100%',
                //   height: topicPageRef.current.offsetHeight,
                height: topicPageRef.current.offsetHeight - 65,
                opacity: 1,
              }}
              exit={{ height: 0, opacity: 0 }}
              transition={{
                duration: 0.2, // Animation duration
                // ease: 'easeInOut', // Easing function
              }}
              //   className={`topic-video-container
              // ${!locked ? 'unlocked' : ''}`}
              //   style={{ opacity: 0.5 }}
            >
              {/* <div style={{ width: '100%', height: '65px' }} /> */}
              {/* <Divider
              variant="middle"
              component="li"
              sx={{ borderBottomWidth: 5, marginBottom: 1 }}
              style={{ borderColor: 'black' }}
            /> */}
              {/* <div>sadasdasfas</div> */}
              <ResponsiveGridLayout
                // style={!locked && { opacity: 0.5 }}
                className="layout"
                layouts={layouts}
                onLayoutChange={onLayoutChange}
                rowHeight={80}
                isResizable={!locked}
                isDraggable={!locked}
                resizeHandles={['nw', 'sw', 'se', 'ne']}
                draggableCancel={'.cancel-drag'}
                // onDragStart={(e) => {
                //   //   e.stopPropagation();
                //   console.log('🌈 on drag');
                //   console.log({ e });
                // }}
                // draggableCancelx={'.outer-widget'}
                // width={412 - 10}
                breakpoints={{ sm: 768, xs: 480, xxs: 0 }}
                cols={cols}
                // onDragStart={handleDragStart}
                // onDragStop={handleDragStop}
              >
                {/* <div>DSFSDFDS</div> */}
                {topic.items.map((item, vidIndex) => {
                  //   console.log('🤨');
                  //   console.log({ item });
                  const video = savedSelections
                    ?.filter((el) => el._id == item)
                    ?.at(0);
                  //   console.log({ video });
                  //   video;
                  if (!video) return;
                  //   console.log({ video });
                  return (
                    <div
                      className="test-grid-container"
                      key={item}
                      //   data-grid={{ x: 0, y: vidIndex * 2, w: 1, h: 2 }}
                      //   w={3}
                      //   h={3}
                      //   onClick={(e) => {
                      //     e.stopPropagation();
                      //     alert('ffdd');
                      //   }}
                      //   key={alphabet[vidIndex]}
                      style={{ color: 'white' }}
                    >
                      <WidgetWrapper
                        onClick={() =>
                          onTreeVideoClickHandler(
                            video.video.id,
                            video.sections,
                            video.looptime

                            // practiceIndex
                          )
                        }
                        unlocked={!locked}
                        //   handleClick={handleClick}
                      >
                        <TopicVid
                          clickHandler={() => {
                            console.log(
                              video.id,
                              video.sections,
                              video.looptime
                            );
                            //   alert('dsfasd');
                            // onTreeVideoClickHandler(
                            //   video.video.id,
                            //   video.sections,
                            //   video.looptime

                            //   // practiceIndex
                            // );
                          }}
                          url={video.video.url}
                          //   looptime={[0, 1]}

                          completed={vidIndex === 1}
                          autoPlay={autoPlay}
                          looptime={[
                            video.looptime[0],
                            Math.min(video.looptime[0] + 4, video.looptime[1]),
                          ]}
                        />
                      </WidgetWrapper>
                    </div>
                  );
                })}

                {notes?.items.map((note, index) => (
                  <div
                    className="test-grid-container text"
                    key={'note' + index}
                    isResizable={true}
                    resizeHandles={['s', 'n', 'ne']}
                  >
                    <WidgetWrapper unlocked={!locked}>
                      <CollageTextbox>{note}</CollageTextbox>
                    </WidgetWrapper>
                  </div>
                ))}

                {/* <div
                className="test-grid-container text"
                key="note0"
                isResizable={true}
                resizeHandles={['s', 'n', 'ne']}
              >
                <WidgetWrapper unlocked={!locked}>
                  <CollageTextbox>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Phasellus consequat, nulla rutrum sagittis cursus, elit nisl
                    condimentum est, id suscipit sem metus pretium turpis.
                  </CollageTextbox>
                </WidgetWrapper>
              </div> */}
                <div
                  className="test-grid-container"
                  style={{ color: 'var(--main-purple-no-transparancy)' }}
                  key="dayProgress"
                  isResizable={true}
                  resizeHandles={['s', 'n', 'ne']}
                >
                  {/* <MonthProgress /> */}

                  <WidgetWrapper unlocked={!locked}>
                    <DayProgress />
                  </WidgetWrapper>
                </div>
              </ResponsiveGridLayout>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      {/* <Divider
        variant="middle"
        // component="li"
        sx={{
          borderBottomWidth: 4,
          marginBottom: 1,
          borderColor: 'var(--main-purple-no-transparancy)',
          opacity: 1,
          marginInline: '10%',
        }}
        // style={{ borderColor: 'black' }}
      /> */}
    </div>
  );
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    let ls = {};
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {};
    } catch (e) {
      /*Ignore*/
    }
    // ls[key] = value;
    global.localStorage.setItem(
      'rgl-8',
      JSON.stringify({ ...ls, [key]: value })
    );
  }
}
