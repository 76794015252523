import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import useFetchOnDemand from '../hooks/useFetchOnDemand';

const initialVideoState = {
  tabIndex: 0,
  activeVideoPage: true, // video page will be loaded at the beginning
  // activeVideoPage: false,
  searchArray: [],
  autoCompleteValue: [],

  allVideos: [],
  recentVideos: [],
  favoriteVideos: [],
  sharedWithMeVideos: [],
  myUploads: [],
  currentVideo: null,

  selectionsCompletedToday: 0,
  savedSelections: [],
  tomorrowsSections: [],
  currentSavedSelections: [],
  allSavedSelections: null,

  currentBar: 0,
  looptime: [0, 1000],
  selectedSections: [],
  prevCarouselIndex: 0,

  showBarOptions: false,
  notesArray: null,
  userVideoData: null,
  filteredVideos: null,
  labels: [],

  autoCompleteInput: null,
  allStudios: null,

  lastNewUser: null,
  namesAndEmails: null,

  practiceDictionary: [],
};

const videoSlice = createSlice({
  name: 'video',
  initialState: initialVideoState,
  reducers: {
    initialize(state, action) {
      console.log('action.payload');
      console.log(action.payload);

      if (action.payload.savedSelections.length === 0) {
        state.tabIndex = 1;
      }

      state.allVideos = [...action.payload.allVideos];
      state.recentVideos = [...action.payload.recentVideos];
      state.favoriteVideos = [...action.payload.favoriteVideos];

      state.sharedWithMeVideos = [...action.payload.sharedWithMeVideos];
      state.myUploads = [...action.payload.myUploads];

      state.allSavedSelections = [...action.payload.savedSelections];
    },
    updateTabIndex(state, action) {
      const nextTab = action.payload;
      if (nextTab === 1 && !state.activeVideoPage) {
        state.activeVideoPage = true;
      }

      state.tabIndex = nextTab;
    },
    updateSearchArray(state, action) {
      state.searchArray = action.payload;
    },
    updateAutoCompleteValue(state, action) {
      state.autoCompleteValue = action.payload;
      state.tabIndex = 2;
    },
    addVideos(state, action) {
      console.log('action.payload');
      console.log(action.payload);

      state.allVideos = [...state.allVideos, ...action.payload.allVideos];
      state.recentVideos = [
        ...state.recentVideos,
        ...action.payload.recentVideos,
      ];

      state.sharedWithMeVideos = [
        ...state.sharedWithMeVideos,
        ...action.payload.sharedWithMeVideos,
      ];
      state.myUploads = [...state.myUploads, ...action.payload.myUploads];
    },
    initializeSavedSelections(state, action) {
      state.savedSelections = [...action.payload.sections];
      state.selectionsCompletedToday = action.payload.selectionsCompletedToday;
      state.tomorrowsSections = action.payload.tomorrowSections;
    },
    intializeTomorrowSections(state, action) {
      state.tomorrowsSections = [...action.payload];
      // state.selectionsCompletedToday = action.payload.selectionsCompletedToday;
    },
    addSavedSelections(state, action) {
      // const category = action.payload.category;
      // let nextPracticeDictionary;
      // if (category != 'unSorted') {
      //   const currentCategories = state.practiceDictionary.map((el) => el.name);
      //   if (currentCategories.includes(category)) {
      //   } else {
      //     nextPracticeDictionary = [
      //       ...state.practiceDictionary,
      //       {
      //         name: category,
      //         items: [action.payload.newSection._id],
      //       },
      //     ];
      //   }

      // nextPracticeDictionary = [...state.practiceDictionary].map(
      //   (section) => {}
      // );
      // }
      state.practiceDictionary = action.payload.practiceDictionary;

      state.allSavedSelections = [
        action.payload.newSection,
        ...state.allSavedSelections,
      ];

      state.savedSelections = [
        action.payload.newSection,
        ...state.savedSelections,
      ];
      // is the belwo needed?
      state.currentSavedSelections = [
        ...state.currentSavedSelections,
        action.payload.newSection,
      ];
      // state.selectedSections = [];
    },

    removeSavedSelection(state, action) {
      state.savedSelections = state.savedSelections.filter(
        (el) => el._id !== action.payload.id
      );
      state.allSavedSelections = state.allSavedSelections.filter(
        (el) => el._id !== action.payload.id
      );
      state.practiceDictionary = action.payload.practiceDictionary;
    },

    completeSavedSelection(state, action) {
      const savedSelection = state.savedSelections.filter(
        (el) => el._id === action.payload.id
      );

      if (action.payload.days === 1) {
        state.tomorrowsSections = [
          ...savedSelection,
          ...state.tomorrowsSections,
        ];
      }

      state.selectionsCompletedToday = [
        ...state.selectionsCompletedToday,
        ...savedSelection,
      ];
      state.savedSelections = state.savedSelections.filter(
        (el) => el._id !== action.payload.id
      );
    },

    snoozeSavedSelection(state, action) {
      const savedSelection = state.savedSelections.filter(
        (el) => el._id === action.payload
      );

      // if (action.payload.days === 1) {
      state.tomorrowsSections = [...savedSelection, ...state.tomorrowsSections];
      // }
      // state.selectionsCompletedToday = [
      //   ...state.selectionsCompletedToday,
      //   ...savedSelection,
      // ];
      state.savedSelections = state.savedSelections.filter(
        (el) => el._id !== action.payload
      );
    },

    updateSavedSelection(state, action) {
      state.savedSelections = [
        ...state.savedSelections.filter((el) => el._id !== action.payload.id),
        action.payload,
      ];

      const indexToUpdate = state.currentSavedSelections.findIndex(
        (el) => el._id === action.payload.id
      );
      const copyOfState = [...state.currentSavedSelections];
      copyOfState[indexToUpdate] = action.payload;
      state.currentSavedSelections = copyOfState;
    },

    updateRecentVideos(state, action) {
      const newVideo = action.payload;
      state.recentVideos = [
        newVideo,
        ...state.recentVideos.filter((video) => video.id != newVideo.id),
      ].slice(0, 10);
      // state.newVideosss
    },
    updateLoopTime(state, action) {
      state.looptime = action.payload;
    },
    playSavedSelection(state, action) {
      state.looptime = action.payload;
      state.selectedSections = [];
    },
    removeSelectedSection(state, action) {
      const index = state.selectedSections.indexOf(action.payload);

      if (index >= 0) {
        state.selectedSections = [];
        state.looptime = [0, 1000];
        console.log('fucked around and found already have');
      }
    },
    updateFromDragEvents(state, action) {
      function range(start, end) {
        return new Array(end - start + 1)
          .fill(undefined)
          .map((_, i) => i + start);
      }

      const {
        startingBarIndex,
        currentBarIndex,
        initialDirection,
        currentDirection,
      } = action.payload;
      console.log(
        'currentBarIndex, initialDirection: ',
        currentBarIndex,
        initialDirection
      );

      // let nextSelectedSections = [...state.selectedSections];
      let endPoints = {
        start: state.selectedSections[0],
        end: state.selectedSections[state.selectedSections.length - 1],
      };

      if (endPoints.start === endPoints.end) {
        // console.log('crack: in the currenbar inde > ');
        if (startingBarIndex === endPoints.start) {
          if (initialDirection === 'down') {
            console.log('trigger A');
            endPoints.end = currentBarIndex;
          } else {
            console.log('trigger B');
            endPoints.start = currentBarIndex;
          }
        } else {
          endPoints = null;
        }
        // else not a span of 1 number
      } else {
        if (currentBarIndex > endPoints.end) {
          console.log('trigger C');
          endPoints.end = currentBarIndex;
        } else if (currentBarIndex < endPoints.start) {
          console.log('trigger D');
          endPoints.start = currentBarIndex;
          // now we know we are dealing with an interior point
        } else {
          if (currentDirection === 'up') {
            console.log('trigger E');
            endPoints.end = currentBarIndex;
          } else {
            console.log('trigger F');
            endPoints.start = currentBarIndex;
          }
        }
      }

      let nextSelectedSections;
      if (!endPoints) {
        nextSelectedSections = [];
      } else {
        nextSelectedSections = range(endPoints.start, endPoints.end);
      }

      state.selectedSections = nextSelectedSections;
      state.showBarOptions = nextSelectedSections.length === 0 ? false : true;

      let nextLooptime;
      if (!endPoints) {
        nextLooptime = [0, 1000];
      } else {
        const startIndex = endPoints.start;
        const endIndex = endPoints.end;
        // console.log(state.currentVideo.sections);
        const startTime =
          state.currentVideo.sections[startIndex].fourCountTimes[0];
        const endTime =
          state.currentVideo.sections[endIndex].fourCountTimes[
            state.currentVideo.sections[endIndex].fourCountTimes.length - 1
          ];

        nextLooptime = [startTime, endTime];
      }

      state.looptime = nextLooptime;

      // state.selectedSections = nextSelectedSections;
    },

    updateSelectedSections(state, action) {
      let nextSelectedSections = [...state.selectedSections];

      if (nextSelectedSections.includes(action.payload)) {
        // nextSelectedSections = nextSelectedSections.filter(
        //   (el) => el !== action.payload
        // );
        nextSelectedSections = [];
      } else if (action.payload.mouseEvent) {
        const value = action.payload.value;
        // nextSelectedSections.push(action.payload);
        // nextSelectedSections.sort((a, b) => a - b);
        if (nextSelectedSections.length === 0) {
          nextSelectedSections = [value];
        } else {
          const first = nextSelectedSections[0];
          const last = nextSelectedSections[nextSelectedSections.length - 1];

          if (value < first) {
            nextSelectedSections = Array.from(
              {
                length: 1 + last - value,
              },
              (v, i) => i + value
            );
          } else {
            nextSelectedSections = Array.from(
              {
                length: 1 + value - first,
              },
              (v, i) => i + first
            );
          }
        }
      } else {
        nextSelectedSections = [action.payload];
      }

      const length = nextSelectedSections.length;
      // console.log('📐 length: ', length);
      // console.log(nextSelectedSections);
      if (length > 0) {
        state.showBarOptions = true;

        const startIndex = nextSelectedSections[0];
        const endIndex = nextSelectedSections[length - 1];
        // console.log(state.currentVideo.sections);
        const startTime =
          state.currentVideo.sections[startIndex].fourCountTimes[0];
        console.log('startTime');
        console.log(startTime.looptime);
        const endTime =
          state.currentVideo.sections[endIndex].fourCountTimes[
            state.currentVideo.sections[endIndex].fourCountTimes.length - 1
          ];
        state.looptime = [startTime, endTime];
      } else {
        state.looptime = [0, 1000];
        state.showBarOptions = false;
      }

      state.selectedSections = nextSelectedSections;
    },
    updateCurrentVideo(state, action) {
      // state.filteredVideos = null;
      console.log('🐶 action payload for curr vide: ', action.payload.video);
      state.currentVideo = action.payload.video;
      // state.prevCarouselIndex = action.payload.practiceIndex;
      state.currentSavedSelections = state.savedSelections.filter(
        (sel) => sel.video === state.currentVideo?.id
      );
      if (action.payload.sections) {
        state.selectedSections = action.payload.sections;
        state.currentBar = action.payload.sections[0];

        if (action.payload.looptime) {
          state.looptime = action.payload.looptime;
        } else {
          const startTime =
            state.currentVideo.sections[action.payload.sections[0]]
              .fourCountTimes[0];
          console.log('startTime');
          console.log(startTime.looptime);
          const endIndex =
            action.payload.sections[action.payload.sections.length - 1];
          const endTime =
            state.currentVideo.sections[endIndex].fourCountTimes[
              state.currentVideo.sections[endIndex].fourCountTimes.length - 1
            ];
          state.looptime = [startTime, endTime];
        }
      } else {
        state.looptime = [0, 1000];
      }
    },

    updateSelectedSectionsSimple(state, action) {
      state.selectedSections = action.payload.sections;
      state.currentBar = action.payload.sections[0];
      state.showBarOptions = true;

      if (action.payload.looptime) {
        state.looptime = action.payload.looptime;
      } else {
        const startTime =
          state.currentVideo.sections[action.payload.sections[0]]
            .fourCountTimes[0];
        console.log('startTime');
        console.log(startTime.looptime);
        const endIndex =
          action.payload.sections[action.payload.sections.length - 1];
        const endTime =
          state.currentVideo.sections[endIndex].fourCountTimes[
            state.currentVideo.sections[endIndex].fourCountTimes.length - 1
          ];
        state.looptime = [startTime, endTime];
      }
    },

    showFirstAvailableVideo(state, action) {
      state.currentVideo = state.sharedWithMeVideos[0] || state.myUploads[0];
    },

    setFilteredVideos(state, action) {
      state.filteredVideos = action.payload;
    },
    resetCurrentVideo(state, action) {
      state.showBarOptions = false;
      // state.filteredVideos = null;
      state.currentVideo = null;
      state.currentBar = 0;
      state.currentSavedSelections = [];
      state.selectedSections = [];
      state.notesArray = [];
    },

    updateCurrentBar(state, action) {
      state.currentBar = action.payload;
    },
    resetShowBarOptions(state, action) {
      state.showBarOptions = false;
      state.selectedSections = [];
      state.looptime = [0, 1000];
    },
    setShowBarOptions(state, action) {
      state.showBarOptions = action.payload;
    },
    initialNotesArray(state, action) {
      console.log('initializing notes array');
      state.notesArray = action.payload;
    },
    userVideoDataUpdate(state, action) {
      console.log('initializing uservideodata array');
      state.userVideoData = action.payload;
    },
    updateNotesArray(state, action) {
      const arrCopy = [...state.notesArray];

      arrCopy[action.payload.noteIndex] =
        action.payload.text.length === 0 ? undefined : action.payload.text;
      // arrCopy[0] = 'dffdkk';
      state.notesArray = arrCopy;
    },
    logout(state) {
      return initialVideoState;
    },
    setAutoCompleteInput(state, action) {
      state.autoCompleteInput = action.payload;
    },
    updateSingeLabel(state, action) {
      // const autoCompleteInputCopy = [...state.autoCompleteInput];
      // state.autoCompleteInput = autoCompleteInputCopy.push(
      //   action.payload.label
      // );
      state.currentVideo.sections[action.payload.barIndex].label =
        action.payload.label;
    },
    setLastNewUser(state, action) {
      state.lastNewUser = action.payload;
    },
    setNamesAndEmails(state, action) {
      state.namesAndEmails = action.payload;
    },
    setAllStudios(state, action) {
      state.allStudios = action.payload;
    },
    initializePracticeDictionary(state, action) {
      if (action.payload) {
        state.practiceDictionary = [...action.payload];
      } else {
        state.practiceDictionary = [];
      }
    },
    addFavoriteToCarousel(state, action) {
      const { videoId, liked } = action.payload;
      let nextFavorites;
      if (liked) {
        nextFavorites = [
          ...new Set([
            ...state.favoriteVideos,
            ...state.sharedWithMeVideos.filter((vid) => vid.id == videoId),
            ...state.myUploads.filter((vid) => vid.id == videoId),
          ]),
        ];
      } else {
        nextFavorites = [
          ...state.favoriteVideos.filter((vid) => vid.id != videoId),
        ];
      }
      state.favoriteVideos = nextFavorites;
    },
  },
});

export const videoActions = videoSlice.actions;
export default videoSlice.reducer;

export function submitSection(label, notes, frequency, category, test = false) {
  console.log('inthinkkk!', label, notes);

  return async (dispatch, getState) => {
    const { video, auth } = getState();
    const selectedSections = video.selectedSections;
    const section = {
      user: auth.user._id,
      video: video.currentVideo.id,
      sections: selectedSections,
      looptime: video.looptime,
      label,
      frequency,
      sectionLabels: selectedSections.map(
        (index) => video.currentVideo.sections[index].label
      ),
      notes,
      test: test || false,
    };

    console.log('👕 section to upload: ', section);

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND}/api/v1/sections`,
      {
        method: 'POST',
        credentials: 'include', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ section, category: category }),
      }
    );
    const filledResponse = await response.json();
    // console.log(response);
    if (!response.ok) {
      console.log('in failed if');
      alert('fail');
    } else {
      console.log({ filledResponse });
      dispatch(
        videoActions.addSavedSelections({
          newSection: filledResponse.data,
          practiceDictionary: filledResponse.practiceDictionary,
        })
      );

      // addSectionHandler(section);
    }
  };
}

export function useUpdateNote(
  docId,
  noteIndex,
  text,
  dependencies = [],
  callback = () => {}
) {
  const dispatch = useDispatch();

  return useFetchOnDemand(
    `${process.env.REACT_APP_BACKEND}/api/v1/userVideoData/update/${docId}`,
    () => {
      dispatch(videoActions.updateNotesArray({ text, noteIndex }));
      callback();
    },
    {
      method: 'PATCH',
      credentials: 'include', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: `{"$set":{"sections.${noteIndex}":{"notes":${JSON.stringify(
        text
      )}}}}`,
    },
    dependencies
  );
}
