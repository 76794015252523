import React, { useRef } from 'react';
import './WidgetWrapper.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';

let clickTimeout;
const unlocked = false;

export default function WidgetWrapper({
  children,
  onClick = () => {},
  unlocked,
}) {
  const isDragging = useRef(null);
  const touchInitial = useRef(null);

  //   const onClick = () => {
  //     alert('clicked');
  //   };

  const handleStart_DEP = () => {
    isDragging.current = true;
    clickTimeout = setTimeout(() => {
      if (isDragging.current === false) {
        onClick();
      }
    }, 200);
  };

  const handleStart = (e) => {
    // alert('touch start');
    const touch = e.touches; // Get the first touch point
    if (touch) {
      touchInitial.current = [touch[0].clientX, touch[0].clientY];
    } else {
      touchInitial.current = [e.clientX, e.clientY];
    }
  };

  const handleEnd_DEP = () => {
    isDragging.current = false;
  };

  const handleEnd = (e) => {
    // alert('beginning of handle end');
    const touch = e.changedTouches; // Get the first touch point of the ended event
    let endPosition;
    if (touch) {
      endPosition = [touch[0].clientX, touch[0].clientY];

      //   touchInitial.curre÷nt = [touch.clientX, touch.clientY];
    } else {
      //   alert('not touch end');
      endPosition = [e.clientX, e.clientY];
    }

    if (
      touchInitial.current[0] - endPosition[0] === 0 &&
      touchInitial.current[1] - endPosition[1] === 0
    ) {
      //   e.stopPropagation();
      //   alert('clicked');
      onClick();
    }
  };

  return (
    <div
      className="outer-widget"
      //   onMouseDown={(e) => {
      //     e.stopPropagation();
      //   }}
      onTouchStart={handleStart}
      onTouchEnd={handleEnd}
      onMouseDown={handleStart}
      onMouseUp={handleEnd}
      //   onClick=
      //   onMouseDown={(e) => {
      //     e.stopPropagation();
      //     alert('this click works');
      //   }}
      //   onClick={(e) => {
      //     // e.stopPropagation();
      //     alert('fuck yall');
      //   }}
    >
      {!unlocked && (
        <div className="cancel-drag widget-hamburger">
          <MoreVertIcon fontSize="30px" />
          {/* </div> */}
        </div>
      )}
      {/* 
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 11,
          position: 'relative',
        }}
      > */}
      {/* {unlocked && <div className="white-border-box"></div>} */}
      <div className={`move-icon-container ${unlocked ? 'unlocked-indv' : ''}`}>
        {false && <OpenWithOutlinedIcon fontSize="inherit" />}
      </div>{' '}
      {/* </div> */}
      {children}
    </div>
  );
}
